import '../styles/user.scss';

import IMask from 'imask';

import jQuery from 'jquery';
const $ = require('jquery');
global.$ = global.jQuery = $;
import 'jquery-ui';

const bootstrap = require('bootstrap');

import 'slider-fotorama/dist/fotorama';
